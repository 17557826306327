import * as React from "react"
const Success = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M9 11.9497L11.1213 14.071L15.364 9.82839M23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12Z"
      stroke="#0F6632"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
)

export default Success
