import * as React from "react"
import Success from "../../svg/form/success"
import Error from "../../svg/form/error"

const FormFeedback = ({ text, success }) => {
  return (
    <div
      className={`form-feedback form-feedback--${
        success ? "success" : "error"
      }`}
    >
      {success ? <Success /> : <Error />}
      {text}
    </div>
  )
}

export default FormFeedback
