import * as React from "react"
import Send from "../../svg/form/send"
import { useForm } from "react-hook-form"
import Cheer from "../../svg/cheer"
import { useEffect, useState } from "react"
import FormFeedback from "../FormFeedback/form-feedback"

const JoinHero = () => {
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm({})
  const [success, setSuccess] = useState(false)
  const [systemError, setSystemError] = useState(null)

  const onSubmit = async data => {
    try {
      const response = await fetch(
        "https://na0sctpz6c.execute-api.eu-central-1.amazonaws.com/process/",
        {
          method: "post",
          body: JSON.stringify({
            content: `Kierowca, zainteresowany współpracą: 
${data.firstName}, ${data.phone}`,
          }),
        }
      )

      if (response.ok) {
        setSuccess(true)
      } else {
        setSystemError(true)
      }
    } catch (error) {
      setSystemError(true)
    }
  }

  useEffect(() => {
    console.log(errors)
    setSuccess(false)
  }, [errors])

  return (
    <div className={"join-hero"}>
      <h1>Zostań kierowcą</h1>
      <div data-g="grid">
        <div data-g="4 5@md">
          <p>
            Dołącz do największej korporacji taxi na Prawobrzeżu! Jesteśmy
            prężnie rozwijającą się firmą. Nasi kierowcy cieszą się solidnymi
            zarobkami oraz elastycznymi godzinami pracy. Działamy na terenie
            całego Szczecina a także poza jego granicami.
          </p>
          <p>
            Jeżeli lubisz prowadzić samochód, masz pozytywną aparycję oraz zapał
            do pracy - nie czekaj dołącz już teraz!
          </p>
          <p>
            Wyślij swoje zgłoszenie za pośrednictwem formularza lub zadzwoń pod
            numer telefonu <a href={"tel:914-615-615"}>914 615 615</a> i zostaw
            swoje dane. Odezwiemy się do Ciebie i umówimy na spotkanie podczas
            którego wszystkiego się dowiesz.
          </p>
          <p>
            Poniżej znajdziesz odpowiedzi na najczęściej zadawane pytania. Do
            zobaczenia!
          </p>
          <div className="hero__subtitle">
            <Cheer />0 zł “wpisowego” do grupy!
          </div>
        </div>
        <form
          data-g="4 12@sm 6@md"
          onSubmit={handleSubmit(data => onSubmit(data))}
        >
          <div className="join-hero__form">
            <div className="form-delimiter">
              <div className={"form-delimiter__text form-delimiter__text--106"}>
                Twoje dane
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <input
                  className={
                    errors.firstName?.type === "required" ? "error" : ""
                  }
                  placeholder="Imię"
                  {...register("firstName", {
                    required: true,
                  })}
                />
              </div>
              <div className="form-group">
                <input
                  className={errors.phone?.type === "required" ? "error" : ""}
                  placeholder="Numer telefonu"
                  {...register("phone", {
                    required: true,
                  })}
                />
              </div>
            </div>
          </div>
          <div className="join-hero__buttons">
            <div>
              <button type={"submit"} className={"button button--primary"}>
                <Send />
                Wyślij zgłoszenie
              </button>
            </div>
            {success && (
              <FormFeedback
                text={"Wysłane! Wkrótce się odezwiemy."}
                success={true}
              />
            )}
            {!success && Object.keys(errors).length > 0 && (
              <FormFeedback
                text={"Zaznaczone pola nie mogą być puste."}
                success={false}
              />
            )}

            {!success && systemError && (
              <FormFeedback
                text={"Wystąpił błąd, spróbuj ponownie."}
                success={false}
              />
            )}
          </div>
        </form>
      </div>
    </div>
  )
}

export default JoinHero
