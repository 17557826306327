import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Clients from "../components/Common/clients"
import JoinHero from "../components/Join/joinHero"
import FAQ from "../components/Join/faq"

const JoinPage = () => (
  <Layout>
    <Seo title={"Dołącz do nas"} description={"Zacznij zarabiać już dziś! Dołącz do grupy Taxi Prawobrzeże i rozpocznij pracę w elastycznych godzinach."} />
    <div data-g="container">
      <JoinHero />
      <FAQ />

      <Clients />
    </div>
  </Layout>
)

export default JoinPage
