import * as React from "react"
import Arrow from "../../svg/join/arrow"
import { useRef, useState } from "react"

const list = {
  "Dlaczego warto dołączyć do grupy Taxi Prawobrzeże?":
    "Jesteśmy największą korporacją taksówkarską w prawobrzeżnej części Szczecina. Charakter wykonywanych przez nas zleceń jest dłuższy, a stawki za kilometr najbardziej korzystne.",
  "Czy wymagana jest Licencja Taxi?":
    "Tak, wymagamy licencji taxi. W przypadku braku licencji istnieje możliwość wynajęcia jej terminowo.",
  "Czy muszę znać topografię Szczecina?":
    "Znajomość topografii miasta z pewnością ułatwi pracę na co dzień, ale nie jest wymagana. Każde zlecenie można realizować z bezpośrednim połączeniem naszej aplikacji z Google Maps co gwarantuje sprawne poruszanie się między zleceniami.",
  "Jakie są formy współpracy z Grupą?":
    "Formą, którą stosujemy to umowa o współpracy.",
  "Jakie są opłaty związane z przyjęciem do Grupy?":
    "Oprócz wyposażenia potrzebnego do pracy brak opłat do przystąpienia do grupy",
  "Czy muszę posiadać własny samochód?":
    "Posiadanie pojazdu jest niezbędne do nawiązania z nami współpracy. Nie ma jednak znaczenia czy samochód jest własnością kierowcy, czy jedynie go wynajmuje.",
  "Jakie są wymagania odnośnie przyjmowanych pojazdów?":
    "Auta, które przyjmujemy do korporacji powinny cechować się czystością, sprawnością techniczną, brakiem uszkodzeń zewnętrznych oraz nie być starsze niż 10 lat.",
  "Czy wymagne jest jakieś dodatkowe wyposażenie?":
    "Obowiązkowym wyposażeniem każdego kierowcy jest smartfon z systemem Android, kasa fiskalna, oklejenie pojazdu, kogut taksówkarski, a także specjalna aplikacja mobilna, którą instalujemy na miejscu podczas podpisywania umowy o współpracę.",
  "W jaki sposób będę otrzymywać zlecenia?":
    "Zlecenia wysyła dyspozytor bezpośrednio na aplikację.",
  "Jaka jest forma rozliczenia za tzw. “Bazę”?":
    "Rozliczeń można dokonywać raz lub dwa razy w miesiącu za pomocą gotówki lub standardowego przelewu bankowego.",
  "Czy występują jakieś dodatkowe koszty oraz umowy?":
    "Nie pobieramy żadnych opłat za rozpoczęcie współpracy.",
}

const FAQItem = ({ heading, content }) => {
  const [active, setActive] = useState(false)
  const [contentHeight, setContentHeight] = useState(0)
  const ref = useRef(null)

  function onClick() {
    if (!active) {
      setContentHeight(ref.current.clientHeight + "px")
    } else {
      setContentHeight(0)
    }
    setActive(!active)
  }

  return (
    <div
      className={`faq-item ` + (active ? "faq-item--active" : "")}
      onClick={onClick}
    >
      <div className="faq-item__heading">
        {heading}
        <Arrow />
      </div>
      <div
        className="faq-item__content-wrapper"
        style={{ height: contentHeight }}
      >
        <div className="faq-item__content" ref={ref}>
          {content}
        </div>
      </div>
    </div>
  )
}

const FAQ = () => {
  return (
    <div className={"faq"}>
      {Object.keys(list).map(key => (
        <FAQItem heading={key} content={list[key]} />
      ))}
    </div>
  )
}

export default FAQ
